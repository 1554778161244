/*
 * It's Happening.
 * Copyright © 2019 It's Happening.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Content, { HTMLContent } from '../components/Content';

export const pageQuery = graphql`
    query StoryByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            fields {
                slug
            }
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                tags
            }
        }
    }
`;

export const StoryTemplate = ({
    slug,
    content,
    contentComponent,
    description,
    tags,
    title
}) => {
    const PostContent = contentComponent || Content;

    return (
        <section className="section">
            <SEO
                title={title}
                description={description}
                tags={tags}
                pathname={slug}
                type="article"
            />
            <div className="container content">
                <h1 className="title">
                    <Link to={slug}>{title}</Link>
                </h1>
                <p>{description}</p>
                <PostContent content={content} />
                {tags && tags.length ? (
                    <div style={{ marginTop: `4rem` }}>
                        <h4>Tags</h4>
                        <ul className="taglist">
                            {tags.map(tag => (
                                <li key={`${tag}tag`}>
                                    <Link
                                        to={`/tags/${kebabCase(tag)}/`}
                                    >
                                        {tag}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
        </section>
    );
};

StoryTemplate.propTypes = {
    slug: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string
};

const Story = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <StoryTemplate
                slug={post.fields.slug}
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
            />
        </Layout>
    );
};

Story.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({})
    })
};

export default Story;
